@import url(https://fonts.googleapis.com/css2?family=Kanit&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit&display=swap);
* {
  font-family: 'Kanit', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  position: relative;
  height: 100%;
}
* {
    font-family: 'Kanit', sans-serif;
    margin: 0;
    padding: 0;
}

main {
    width: 100%;
    height: 100%;
}

#myVideo {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000%;
    margin-left: 50vw;
    -webkit-transform: translate(-50%);
            transform: translate(-50%)
}


#qualityVideo {
    margin-left: auto;
    margin-right: auto;
    margin-left: 50vw;
    -webkit-transform: translate(-50%);
            transform: translate(-50%)
}


.drop {
    -webkit-filter: drop-shadow(5px 5px 40px black);
            filter: drop-shadow(5px 5px 40px black);
}
