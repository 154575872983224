@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

* {
    font-family: 'Kanit', sans-serif;
    margin: 0;
    padding: 0;
}

main {
    width: 100%;
    height: 100%;
}

#myVideo {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000%;
    margin-left: 50vw;
    transform: translate(-50%)
}


#qualityVideo {
    margin-left: auto;
    margin-right: auto;
    margin-left: 50vw;
    transform: translate(-50%)
}


.drop {
    filter: drop-shadow(5px 5px 40px black);
}