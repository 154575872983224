@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

* {
  font-family: 'Kanit', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  position: relative;
  height: 100%;
}